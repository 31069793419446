import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IRent } from '../../types/rent-types';
import styles from './rents.module.less';
import { OrangeButton } from '../buttons/orange-button';
import { CustomImage } from '../custom-image/custom-image';

interface IRentsProps {
    rents: IRent[];
    phone: string;
}

export const Rents: FC<IRentsProps> = ({ rents, phone }) => {
    return (
        <div className={styles.rents__wrapper}>
            {rents.length ? (
                <div className={styles.title__wrapper}>
                    <h1>Аренды</h1>
                </div>
            ) : (
                <></>
            )}
            <div className={styles.cards}>
                {rents.map((rent) => (
                    <div className={styles.cards__item} key={rent.car_id}>
                        <article className={styles.card}>
                            <div className={styles.card__image}>
                                <CustomImage src={rent.car_photo} />
                            </div>
                            <div className={styles.card__content}>
                                <h3 className={styles.card__title}>
                                    <b>{rent.car_name}</b>
                                </h3>
                                <p className={styles.card__descr}>
                                    <span>
                                        <b>Гос. номер:</b>{' '}
                                        {rent.car_license_plate}
                                    </span>
                                </p>
                                <p className={styles.card__descr}>
                                    <span>
                                        <b>Начало аренды:</b>{' '}
                                        {rent.start_rent.toLocaleString()}
                                    </span>
                                </p>
                                <p>
                                    <b>Аренда с выкупом: </b>
                                    {rent.car_daily_rent} ₽/дн.
                                </p>
                                {/* <Link
                                    to={`https://www.sberbank.com/sms/pbpn?requisiteNumber=${phone}`}
                                    className={styles.card__btn}
                                >
                                    <OrangeButton
                                        children={'Оплатить аренду'}
                                        handleClick={() => 1}
                                    />
                                </Link> */}
                            </div>
                        </article>
                    </div>
                ))}
            </div>
        </div>
    );
};
