import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import { CloseCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import styles from './webcam.module.less';
import { WebcamPreviewsList } from './webcam-preview-list';

const videoConstraints = {
    facingMode: 'environment',
};

export const WebcamCapture = ({ onCapture, onCancel, onRemove, mediaList }) => {
    const [isVideoMode, setIsVideoMode] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);

    const capturePhoto = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        onCapture(imageSrc, 'photo');
    }, [webcamRef]);

    const captureVideoStart = () => {
        setIsRecording(true);
        const mediaRecorder = new MediaRecorder(webcamRef.current.stream, {
            mimeType: 'video/webm', // Или другой подходящий формат
        });

        mediaRecorderRef.current = mediaRecorder;

        let recordedChunks = [];
        mediaRecorder.ondataavailable = (e) => {
            recordedChunks.push(e.data);
        };

        mediaRecorder.onstop = () => {
            const videoBlob = new Blob(recordedChunks, { type: 'video/webm' });
            const videoUrl = URL.createObjectURL(videoBlob);
            onCapture(videoUrl, 'video');
        };

        mediaRecorder.start();
    };

    const captureVideoStop = () => {
        setIsRecording(false);
        mediaRecorderRef.current.stop();
    };

    return (
        <div className={styles.camera_container}>
            <div className={styles.webcam_wrapper}>
                <div className={styles.webcam_inner}>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                    />
                </div>
            </div>
            {/* <div className={styles.back_button} onClick={onCancel}>
                Выйти из режима съёмки и продолжить добавление сервиса{' '}
                <CloseCircleOutlined />
            </div> */}
            <div className={styles.camera_controls}>
                {/* <button
                    type="button"
                    className={`${styles.control_button} ${!isVideoMode ? styles.active : ''}`}
                    onClick={() => setIsVideoMode(false)}
                >
                    Режим Фото
                </button> */}

                {/* <button
                    className={`${styles.control_button} ${isVideoMode ? styles.active : ''}`}
                    onClick={() => setIsVideoMode(true)}
                >
                    Видео
                </button> */}

                {isVideoMode ? (
                    isRecording ? (
                        <button
                            className={styles.control_button}
                            onClick={captureVideoStop}
                        >
                            Остановить
                        </button>
                    ) : (
                        <button
                            className={styles.control_button}
                            onClick={captureVideoStart}
                        >
                            Записать
                        </button>
                    )
                ) : (
                    <button
                        type="button"
                        className={styles.control_button}
                        onClick={capturePhoto}
                    >
                        Сделать фото
                    </button>
                )}
                <div className={styles.control_button} onClick={onCancel}>
                    Продолжить <RightCircleOutlined />
                </div>
            </div>
            <WebcamPreviewsList onRemove={onRemove} mediaList={mediaList} />
        </div>
    );
};
